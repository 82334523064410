export default {
  apiKey: "AIzaSyCu3oPMnZvmPzV_b9JOuv_7MDeektwiq9U",
  authDomain: "ud-and-pa.firebaseapp.com",
  databaseURL: "https://ud-and-pa.firebaseio.com",
  projectId: "ud-and-pa",
  storageBucket: "ud-and-pa.appspot.com",
  messagingSenderId: "976371146733",
  appId: "1:976371146733:web:c39ba0d84320ef4f4811e4",
  measurementId: "G-ZH1WYTGVF2",
};
