<template >
  <v-app ><router-view> </router-view></v-app>
</template>
<script>
//import firebase from "firebase/app";
import "firebase/auth";
 import "firebase/firestore";
export default {
  methods: {},
  created() {
   
     this.$router.replace({name:'Home'})

  },
};
</script>

