import "@fortawesome/fontawesome-free/css/all.css";
import 'font-awesome/css/font-awesome.min.css'
import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi" || "fab" || "fa4",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.blue,
        secondary:colors.grey.lighten3,
        accent: "#FFFFFF",
        // error: "#8f8d8d",
        error: colors.red,
        background: "#FFFFFF",
        info: colors.red.lighten5,
        warning:colors.blue,
        success:"#E3F2FD"
      },
      dark: {
        primary: colors.purple,
        secondary: "#292828",
        accent: "#191919",
        error: "#363636",
        info: colors.purple,
        success: "#4CAF50",
        warning: "#FFFFFF",
        background: "#121212",
      },
    },
  },
});
