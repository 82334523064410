import Vuex from 'vuex'
import {
  vuexfireMutations,
  firestoreAction
} from 'vuexfire'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import Vue from 'vue'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userUnapproveCounter: [],
    mainDialogMessage: "",
    error: '',
    userInfo: [],
    mainDialog: false,
    userNotifications: [],
    userNotificationsLists: [],
    userNotificationsListsFinal: [],
    collection: '',
    user: [],
    userList: [],
    userUnapproved: [],
    report: [],

    districtUser: '',
    districtReport: '',

    nulmUser: '',

    nulmReport: '',
    userPagination: 1
  },
  mutations: {
    changeUserInfo(state, n) {
      state.userInfo = n
    },
    
    changeMainDialogMessage(state, n) {
      state.mainDialogMessage = n
    },
    changeMainDialog(state, n) {
      state.mainDialog = n
    },

    changeError(state, n) {
      state.error = n
    },
    changeUserNotificationsListsFinal(state, n) {
      state.userNotificationsListsFinal = n
    },
    changeUser(state, n) {

      state.user = n
    },
    changeUserDistrict(state, n) {
      state.districtUser = n
    },
    changeAgendaDistrict(state, n) {
      state.districtAgenda = n
    },
    changeReportDistrict(state, n) {
      state.districtReport = n
    },
    changeReportNulm(state, n) {
      state.nulmReport = n
    },
    changeAgendaNulm(state, n) {
      state.nulmAgenda = n
    },
    changeUserNulm(state, n) {
      state.nulmUser = n
    },
    changeUserPagination(state, n) {
      state.userPagination = n
    },

    ...vuexfireMutations
  },

  actions: {
    binduserInfoRef: firestoreAction(context => {
      return context.bindFirestoreRef(
        'userInfo',
        firebase
        .firestore()
        .collection(localStorage.getItem('userDb'))
        .doc(firebase.auth().currentUser.uid)
      )
    }),
    binduserNotificationsLists: firestoreAction(context => {
      if (store.state.userInfo) {
        if (['SMMU', 'CMMU'].includes(store.state.userInfo.nulm)) {

          return context.bindFirestoreRef(
            'userNotificationsLists',
            firebase
            .firestore()
            .collection(`notifications`)
            .doc(
              `${store.state.userInfo.nulm.toLowerCase()}_${store.state.userInfo.district.toLowerCase()}`
            )
          )

        } else if (['SUSV', 'SEP', 'ESTP'].includes(store.state.userInfo.nulm)) {

          return context.bindFirestoreRef(
            'userNotificationsLists',
            firebase
            .firestore()
            .collection(`notifications`)
            .doc(
              `${store.state.userInfo.nulm.toLowerCase()}_${store.state.userInfo.district.toLowerCase()}`
            )
          )
        } else if (["SM&ID", "SUH"].includes(store.state.userInfo.nulm)) {

          return context.bindFirestoreRef(
            'userNotificationsLists',
            firebase
            .firestore()
            .collection(`notifications`)
            .doc(
              `${store.state.userInfo.department.toLowerCase()}_${store.state.userInfo.district.toLowerCase()}`
            )
          )
        } else return {}
      }
    }),
    binduserNotifications: firestoreAction(async (context) => {

      {
        if (store.state.userNotificationsListsFinal.length == 0) {
          return ['']
        } else if (store.state.userNotificationsListsFinal.length > 0) {
          return await context.bindFirestoreRef(
            'userNotifications',
            firebase
            .firestore()
            .collection(`notifications`)
            .where(
              firebase.firestore.FieldPath.documentId(),
              'in',
              store.state.userNotificationsListsFinal
            )
          )
        }
      }
    }),

    bindUserUnapprove: firestoreAction(context => {
      let userArray = store.state.userInfo.nulm
      if (
        ['SMMU'].includes(
          userArray.toUpperCase()
        )
      ) {
        return context.bindFirestoreRef(
          'userUnapproved',
          firebase
          .firestore()
          .collection('usersUnapproved')
          .where(
            'nulm',
            'in',
            ['SMMU', 'CMMU']
          )
        )
      } else if (['CMMU'].includes(
          userArray.toUpperCase()
        )) {
        return context.bindFirestoreRef(
          'userUnapproved',
          firebase
          .firestore()
          .collection('usersUnapproved')
          .where(
            "district", "==", store.state.userInfo.district
          )
          .where("nulm","!=","SMMU")

        )
      }
      // if (
      //   ['SUSV', 'ESTP', 'SEP'].includes(
      //     userArray[0].replace('smnid', 'sm&id').toUpperCase()
      //   )
      // ) {
      //   return context.bindFirestoreRef(
      //     'userUnapproved',
      //     firebase
      //     .firestore()
      //     .collection('usersUnapproved')
      //     .where(
      //       'district',
      //       '==',
      //       userArray[1][0].toUpperCase() +
      //       userArray[1].substring(1).toLowerCase()
      //     )
      //     .where(
      //       'nulm',
      //       '==',
      //       userArray[0].replace('smnid', 'sm&id').toUpperCase()
      //     )
      //   )
      // }
    }),
    bindUserUnapproveList: firestoreAction(context => {
      if (localStorage.getItem('userPendingCollection') && localStorage.getItem('userPendingDocument')) {
        return context.bindFirestoreRef(
          'userUnapproveCounter',
          firebase
          .firestore()
          .collection(localStorage.getItem('userPendingCollection'))
          .doc(localStorage.getItem('userPendingDocument').toUpperCase())
        )
      }
    }),
    bindUserList: firestoreAction(context => {
      let User = store.state.user,
        Pagination = store.state.userPagination,

        userArrayContent = []

      for (let i = ((Pagination * 5) - 5); i < ((Pagination * 5) - 5) + 5; i++) {

        userArrayContent.push(User[i] ?? "xxx")

      }


      if (userArrayContent.length > 0) {
        return context.bindFirestoreRef(
          'userList',
          firebase
          .firestore()
          .collection(localStorage.getItem('userNulmPref'))
          .where(
            firebase.firestore.FieldPath.documentId(),
            'in',
            userArrayContent
          )
        )
      }
    }),
    bindReport: firestoreAction(context => {
      return context.bindFirestoreRef(
        'report',
        firebase
        .firestore()
        .collection('Reports')
        .where('district', '==', store.state.districtReports)
        .where('nulm', '==', store.state.nulmReports)
      )
    }),


    //All unbinding done here
    unbindUserInfo: firestoreAction(({
      unbindFirestoreRef
    }) => {
      unbindFirestoreRef('userInfo')
    }),
    unbindUserApproval: firestoreAction(({
      unbindFirestoreRef
    }) => {
      unbindFirestoreRef('userUnapproved')
    }),
    unbindReport: firestoreAction(({
      unbindFirestoreRef
    }) => {
      unbindFirestoreRef('report')
    }),
    unbinduserNotificationsLists: firestoreAction(({
      unbindFirestoreRef
    }) => {
      unbindFirestoreRef('userNotificationsLists')
    }),
    unbindUser: firestoreAction(({
      unbindFirestoreRef
    }) => {
      unbindFirestoreRef('user')
    }),
    unbindUserList: firestoreAction(({
      unbindFirestoreRef
    }) => {
      unbindFirestoreRef('userList')
    }),
    unbindUserUnapproveList: firestoreAction(({
      unbindFirestoreRef
    }) => {
      unbindFirestoreRef('userUnapproveCounter')
    })
  },
  getters: {
    getNoti: state => {

      return state.userNotificationsLists
    }
  }
})

export default store