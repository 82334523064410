import Vue from "vue";

const uploadFile = () => import('../routes/uploadFile.vue');
const User = () => import('../routes/User.vue');
const Home = () => import('../routes/Home.vue');
const Reports = () => import('../routes/Reports.vue');
const PasswordForget = () => import('../routes/PasswordForget.vue');
const Login = () => import('../routes/Login.vue');
const SMSSender = () => import('../routes/SMSSender.vue');
const Admin = () => import('../routes/Admin.vue');
const Notifications = () => import('../routes/Notification.vue');
const Profiles = () => import('../routes/Profiles.vue');
const ContactUs = () => import('../routes/ContactUs.vue');
const Grievances = () => import('../routes/Grievances.vue');
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/Admin",
    name: "Admin",
    component: Admin,
    children:[
      {
        path: "/SMSSender/:recipient",
        name: "SMSSender",
        component: SMSSender,
      },
      {
        path: "/uploadFile",
        name: "uploadFile",
        component: uploadFile,
      },
      
      {
        path: "/Home",
        name: "Home",
        component: Home
      },
     
    
      {
        path: "/User",
        name: "User",
        component: User
      },
     
    
      
      {
        path: "/Notifications/:id",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "/Reports",
        name: "Reports",
        component: Reports,
      },
      {
        path: "/Profiles",
        name: "Profiles",
        component: Profiles,
      },
      {
        path: "/Grievances",
        name: "Grievances",
        component: Grievances,
      },
    ],
    
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: ContactUs,
  },
 
  
  {
    path: "/PasswordForget",
    name: "PasswordForget",
    component: PasswordForget,
  },
  
 
];
const router = new VueRouter({
  routes,
 
  });

export default router;