


import Vue from "vue";
import axios from "axios";



import firebaseApp from "./components/firebaseInit"
firebaseApp
import store from "./store/index";
import Home from "./routes/Main.vue";
import vuetify from "./plugins/vuetify";
import router from "./routes/router.js";
import Vuetify from "vuetify/lib";

Vue.config.devtools = true
Vue.config.productionTip = false
Vue.use(Vuetify);
new Vue({
  e1: "#app",
  store,
  router,
  vuetify,
  axios,
  render: (h) => h(Home),
}).$mount("#app");

const Navbar = () => import('./routes/Navbar.vue')
Vue.component("Navbar", Navbar);
